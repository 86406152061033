<template>
  <FormItem
    :clearable="clearable"
    :deletable-chips="multi"
    :dense="dense"
    :disabled="disabled"
    :hint="hint"
    :label="label || name"
    :name="name"
    :persistent-hint="persistentHint"
    :rules="rules"
    :type="multi ? 'autocomplete' : 'select'"
    :value="value"
    :values="list"
    @input="onInput"
  />
</template>

<script>
import { mapState } from 'vuex'
import FormItem from '@components/forms/FormItem.vue'
import { debounce } from 'lodash'

export default {
  name: 'FormProjects',
  components: { FormItem },
  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 3,
    },
    xl: {
      type: [String, Number],
      default: 3,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    icon: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Progetto',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    prefix: {
      default: null,
      required: false,
      type: String,
    },
    rules: {
      default: '',
      required: false,
      type: String,
    },
    suffix: {
      default: null,
      required: false,
      type: String,
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  computed: {
    ...mapState('adminProjects', ['list']),
  },
  methods: {
    onInput: debounce(function (value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>

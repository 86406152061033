<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="macro"
        :values="macrosByContext({ category })"
        type="autocomplete"
        deletable-chips
        name="Filtra per commessa"
        :cols="3"
        dense
      />

      <FormItem
        v-model="category"
        :values="categoriesByContext({ macro })"
        type="autocomplete"
        deletable-chips
        :cols="3"
        name="Filtra per categoria"
        dense
      />

      <FormItem
        v-model="active"
        type="select"
        :values="[
          { value: 'true', text: 'Attivo' },
          { value: 'false', text: 'Inattivo' },
        ]"
        name="Stato"
        :cols="2"
        dense
      />

      <FormItem
        v-model="type"
        name="Tipologia"
        hint="Ricerca per tipologia"
        type="autocomplete"
        deletable-chips
        :values="[
          { value: 'remote', text: 'Intervento Remoto' },
          { value: 'onfield', text: 'Attività On-Field' },
          { value: 'phone', text: 'Intervento Telefonico' },
          { value: 'mail', text: 'Supporto Via Mail' },
          { value: 'other', text: 'Altro' },
        ]"
        :cols="2"
        dense
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'ticketsActivities/getFiltersFields',
  mutationType: 'ticketsActivities/SET_FILTER_FIELDS',
})

export default {
  name: 'TicketActivitiesSearch',
  components: { FormItem, BaseSectionSearch },
  computed: {
    ...mapFields(['active', 'macro', 'category', 'subcategory', 'type']),
    ...mapState('ticketsActivities', ['filters']),
    ...mapGetters('ticketsActivities', {
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
    }),
  },
  async mounted() {
    await this.getDropdownList({})
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapActions('ticketsActivities', ['getDropdownList']),
    ...mapMutations('ticketsActivities', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>

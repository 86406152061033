<template>
  <v-container fluid class="px-lg-10">
    <TicketsActivitiesSearch @search="() => $refs.table.refresh()" />
    <BaseTable
      ref="table"
      title="Attività"
      module="ticketsActivities"
      :headers="headers"
      :actions="actions"
      sortby="macro"
    >
      <template v-slot:actions>
        <v-btn
          v-if="canUser('tickets_activities', 'create')"
          color="green"
          dark
          tile
          @click="openModal"
        >
          Aggiungi attività
        </v-btn>
      </template>

      <template v-slot:item.type="{ item }">
        {{ typeMap[item.type] || '-' }}
      </template>
    </BaseTable>
    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title v-if="editMode"> Modifica Dettagli</v-card-title>
        <v-card-title v-else>Crea nuovo attività</v-card-title>
        <v-card-text>
          <TicketsActivitiesForm
            :title="null"
            :edit="editMode"
            @submitted="onSubmitted"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import TicketsActivitiesForm from '@components/tickets/activities/TicketsActivitiesForm.vue'
import TicketsActivitiesSearch from '@components/tickets/activities/TicketActivitiesSearch.vue'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'TicketActivitiesSearch',
  components: { TicketsActivitiesForm, TicketsActivitiesSearch, BaseTable },
  page: {
    title: 'Attività tickets',
  },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Commessa',
          value: 'macro',
        },
        {
          text: 'Categoria',
          value: 'category',
        },
        {
          text: 'Sottocategoria',
          value: 'subcategory',
        },
        {
          text: 'Priorità',
          value: 'priority',
        },
        {
          text: 'Attiva',
          value: 'active',
        },
        {
          text: 'Tipologia',
          value: 'type',
        },
        {
          text: 'Sla 1',
          value: 'sla_1',
        },
        {
          text: 'Sla 2',
          value: 'sla_2',
        },
        {
          text: 'Sla 3',
          value: 'sla_3',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          enabled: true,
          onItemCondition: () => this.canUser('tickets_activities', 'update'),
          handler: this.openModal,
        },
        {
          label: 'Elimina asset',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: () => this.canUser('tickets_activities', 'delete'),
          handler: this.remove,
        },
      ],
      typeMap: {
        remote: 'Intervento Remoto',
        onfield: 'Attività On-Field',
        phone: 'Intervento Telefonico',
        mail: 'Supporto Via Mail',
        other: 'Altro',
      },
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('ticketsActivities', ['getDropdownList', 'removeItem']),
    ...mapMutations('ticketsActivities', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },

    async onSubmitted() {
      this.modalClose()
      await this.getDropdownList()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
    async remove(item) {
      const res = await this.$dialog.confirm({
        text:
          "Proseguire con l'eliminazione del attività ?\n l'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      this.$refs.table.refresh()
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseSectionSearch',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('v-row',{attrs:{"dense":""}},[_c('FormItem',{attrs:{"values":_vm.macrosByContext({ category: _vm.category }),"type":"autocomplete","deletable-chips":"","name":"Filtra per commessa","cols":3,"dense":""},model:{value:(_vm.macro),callback:function ($$v) {_vm.macro=$$v},expression:"macro"}}),_c('FormItem',{attrs:{"values":_vm.categoriesByContext({ macro: _vm.macro }),"type":"autocomplete","deletable-chips":"","cols":3,"name":"Filtra per categoria","dense":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('FormItem',{attrs:{"type":"select","values":[
        { value: 'true', text: 'Attivo' },
        { value: 'false', text: 'Inattivo' } ],"name":"Stato","cols":2,"dense":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}}),_c('FormItem',{attrs:{"name":"Tipologia","hint":"Ricerca per tipologia","type":"autocomplete","deletable-chips":"","values":[
        { value: 'remote', text: 'Intervento Remoto' },
        { value: 'onfield', text: 'Attività On-Field' },
        { value: 'phone', text: 'Intervento Telefonico' },
        { value: 'mail', text: 'Supporto Via Mail' },
        { value: 'other', text: 'Altro' } ],"cols":2,"dense":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }